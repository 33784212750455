import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '@components/layout'
import Hero from '@components/hero'
import Markdown from '@components/markdown'

export default ({ data, location }) => (
  <Layout className="about" location={location}>
    <Helmet title={`About | ${data.contentfulPerson.name}`} />
    <Hero
      data={{
        name: 'About Me',
        shortBio: "Find out who I am and what I'm all about.",
      }}
    />
    <div className="wrapper">
      <Markdown data={data.contentfulMarkdown} />
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query AboutQuery {
    contentfulPerson(contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" }) {
      name
    }
    contentfulMarkdown(contentful_id: { eq: "DEznFWGhJO0StgnN69A1j" }) {
      name
      markdown {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
